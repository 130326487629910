import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import clsx from 'clsx'
import { type ReactNode } from 'react'
import { ContentTile } from '../content'
import { MoreIcon } from '../icons'
import { TypographyLabel } from '../typography'
import fallbackAvatarImage from '#/images/fallbackAvatar.webp'
import { useNavigate } from '@remix-run/react'
import { truncateStringInMiddle } from '#app/utils/misc.tsx'

const Popover = ({
	PopoverButton = <MoreIcon />,
	options = [],
	popoverType = 'list',
	user = undefined,
	userProfile = undefined,
	logOut = undefined,
	searchValue = undefined,
}: {
	PopoverButton: ReactNode
	options?: {
		title: string
		description?: string
		action: () => void
		leftIcon?: ReactNode
		rightIcon?: ReactNode
		isVerified?: boolean
		image?: string
	}[]
	popoverType?: 'list' | 'search' | 'profile' | 'calendar'
	user?: {
		name?: string
		wallet?: string
		slug?: string
		description?: string
		image?: string
		isVerified?: boolean
		accessLevel?: { [key: string]: boolean }
		action?: (str: string) => {}
	}
	userProfile?: () => void
	logOut?: () => void
	searchValue?: string
}) => {
	const navigate = useNavigate()
	return (
		<Menu as="div">
			<div>
				{popoverType === 'search' ? (
					<>{PopoverButton}</>
				) : (
					<MenuButton className="flex h-full">
						<span className="sr-only">Open options</span>
						{PopoverButton}
					</MenuButton>
				)}
			</div>
			<MenuItems
				className={clsx(
					'group absolute right-12 z-30 mt-2 min-w-[277px] max-w-[390px] origin-top-right rounded-[16px] bg-white focus:outline-none dark:bg-textPrimaryLight',
				)}
			>
				<div className="rounded-2xl border border-uiPrimaryLight p-4 ring-1 ring-uiPrimaryLight ring-opacity-5 dark:border-uiPrimaryDark dark:ring-uiPrimaryDark">
					{popoverType === 'profile' && user !== undefined && (
						<MenuItem as="div" className="flex w-full flex-col">
							{({ close }) => (
								<>
									<div
										className={clsx(
											'flex flex-row gap-x-2 px-2 text-textPrimaryLight dark:text-textPrimaryDark',
											userProfile ? 'hover:cursor-pointer' : '',
										)}
										onClick={() => {
											if (userProfile) userProfile()
											close()
										}}
									>
										<ContentTile
											title={user.name || ''}
											description={truncateStringInMiddle(user.wallet) || ''}
											image={user.image || fallbackAvatarImage}
											variant="user"
											isVerified={true}
											showBorder={false}
											showCaret={true}
										/>
									</div>
									<div className="border-b border-uiPrimaryLight pt-0 dark:border-uiSecondaryDark"></div>
								</>
							)}
						</MenuItem>
					)}
					{options &&
						options
							.filter(option => {
								if (popoverType === 'search' && searchValue) {
									return (
										option.title
											.toLowerCase()
											.includes(searchValue.toLowerCase()) ||
										(option.description &&
											option.description
												.toLowerCase()
												.includes(searchValue.toLowerCase()))
									)
								} else {
									return true
								}
							})
							.map(option => (
								<MenuItem key={option.title}>
									{({ close }) => (
										<div
											className={clsx(
												'flex w-full overflow-hidden text-sm',
												'text-textPrimaryLight dark:text-textPrimaryDark',
												popoverType === 'list' &&
													'justify-start hover:bg-uiSecondaryLight hover:dark:bg-uiSecondaryDark',
												popoverType === 'profile' &&
													'my-2 px-4 py-2 hover:cursor-pointer hover:bg-uiSecondaryLight hover:dark:bg-uiSecondaryDark',
											)}
											onClick={() => {
												option.action()
												close()
											}}
										>
											{/* List Type */}
											{!option.leftIcon &&
												options.some(opt => opt.leftIcon) && (
													<div className="mr-[3.5px] h-6 w-6"></div>
												)}
											{option.leftIcon && (
												<div className="mr-[3.5px] h-6 w-6">
													{option.leftIcon}
												</div>
											)}
											{popoverType === 'list' && (
												<div className="text-left">
													<TypographyLabel size="medium" isBold={true}>
														{option.title}
													</TypographyLabel>
												</div>
											)}
											{option.rightIcon && (
												<div className="ml-auto h-6 w-6">
													{option.rightIcon}
												</div>
											)}

											{/* Search Type */}
											{popoverType === 'search' && (
												<div className="w-full min-w-[360px]">
													<ContentTile
														variant="user"
														image={option.image}
														isVerified={option.isVerified}
														title={option.title}
														description={
															option.description ? option.description : ''
														}
													/>
												</div>
											)}
											{/* Profile Type */}
											{popoverType === 'profile' && (
												<TypographyLabel
													size="medium"
													isBold={true}
													additionalClasses="text-textSecondaryLight dark:text-textSecondaryDark"
												>
													{option.title}
												</TypographyLabel>
											)}
											{/* Calendar Type */}
										</div>
									)}
								</MenuItem>
							))}
					{popoverType === 'profile' &&
						user &&
						user.accessLevel &&
						user.accessLevel?.createCollection && (
							<MenuItem>
								{({ close }) => (
									<div
										className={clsx(
											'flex w-full flex-col overflow-hidden text-sm',
											'text-textSecondaryLight dark:text-textSecondaryDark',
										)}
										onClick={() => {
											navigate('/collectionAdd')
											close()
										}}
									>
										<div className="border-t border-uiPrimaryLight pt-2 dark:border-uiSecondaryDark"></div>
										<div className="px-4 py-2 text-left hover:cursor-pointer hover:bg-uiSecondaryLight hover:dark:bg-uiSecondaryDark">
											<TypographyLabel size="medium" isBold={true}>
												Add Collection
											</TypographyLabel>
										</div>
									</div>
								)}
							</MenuItem>
						)}
					{popoverType === 'profile' && logOut && (
						<MenuItem>
							{({ close }) => (
								<div
									className={clsx(
										'flex w-full flex-col overflow-hidden text-sm',
										'text-textSecondaryLight dark:text-textSecondaryDark',
									)}
									onClick={() => {
										logOut()
										close()
									}}
								>
									<div className="border-t border-uiPrimaryLight pt-2 dark:border-uiSecondaryDark"></div>
									<div className="px-4 py-2 text-left hover:cursor-pointer hover:bg-uiSecondaryLight hover:dark:bg-uiSecondaryDark">
										<TypographyLabel size="medium" isBold={true}>
											Sign Out
										</TypographyLabel>
									</div>
								</div>
							)}
						</MenuItem>
					)}
				</div>
			</MenuItems>
		</Menu>
	)
}

export default Popover
